<template>
  <div class="comp-banner">
    <img class="img" src="../asset/component/banner/banner.png" alt="">
  </div>
</template>

<script>
export default {

};
</script>

<style lang="less">
.comp-banner {
  .img {
    display: block;
    width: 100%;
    pointer-events: none;
  }
}
</style>
